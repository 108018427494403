import { Outlet, ScrollRestoration } from "react-router-dom";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import styled from "@emotion/styled";
import { menuItems } from "../../data/menuItems";
import MobileMenu from "../Menu/MobileMenu";
import { useState } from "react";
import DesktopMenu from "../Menu/DesktopMenu";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { UnisonLogo } from "../../icons/UnisonLogo";
import { HEADER_HEIGHT } from "../../constants";
import Footer from "../Footer/Footer";
import useMediaQuery from '@mui/material/useMediaQuery';
import Link from '@mui/material/Link';


const StyledToolbar = styled(Toolbar)(() => ({
  // Override media queries injected by theme.mixins.toolbar
  '@media all': {
    minHeight: HEADER_HEIGHT, // same as header (used by appBar) 
  },
}));
function HeaderFooterLayout() {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [openSection, setOpenSection] = useState('0');
  const [subSections, setSubSections] = useState([]);
  //const navigate = useNavigate();
  /*useEffect(() => {
    navigate('/programs');
  }, [])*/

  const isDesktop = useMediaQuery('(min-width:900px)')

  const handleDrawerToggle = () => {
    setMenuOpen((prevState) => !prevState);
  };

  const handleMenuOptionToggle = (e, sections) => {
    e.stopPropagation();
    handleDrawerToggle();
    setSubSections(sections);
  };

  const getParentSectionId = (id) => {
    return id.length > 1 ? id.substring(0, id.length - 1) : '0';
  }

  const handleOnClickToggle = (e, sectionId) => {
    e.stopPropagation();
    // TODO: Improve this, its a recursive function
    const isClickedSectionOpen = openSection === sectionId
    if (isClickedSectionOpen) {
      setOpenSection(getParentSectionId(sectionId));
    } else {
      if (getParentSectionId(openSection) === sectionId) {
        setOpenSection('0');
      } else {
        setOpenSection(sectionId);
      }
    }
  }


  return (
    <>
      <ScrollRestoration />
      <AppBar
        elevation={0}
        color="transparent"
        position="fixed"
        sx={{
          zIndex: (theme) => isDesktop ? theme.zIndex.drawer + 1 : undefined,
          backgroundImage: {
            xs: "linear-gradient(to right, white,transparent, transparent)",
            sm: "linear-gradient(to right, white,transparent, transparent, transparent, transparent, transparent)",
          }
        }}>
        <StyledToolbar>
          <Link aria-label="Go to home page" href="/" sx={{ display: 'flex', flexDirection: 'row' }}>
            <UnisonLogo sx={{ fontSize: 100 }} />
          </Link>
          {/** DESKTOP */}
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }} alignItems="center" justifyContent="flex-end" gap={4}>
            {menuItems.map(({ link, isAnchor, text, subSections }) => (
              <Box key={text} sx={{ display: "flex" }}>

                <Typography textAlign="center" sx={{ margin: 'auto' }}><Link isAnchorLink={isAnchor} onClick={() => setMenuOpen(false)} href={link} sx={{ color: 'inherit', textDecoration: 'none' }}>{text}</Link></Typography>
                {subSections &&
                  <IconButton aria-label="toggle-submenu" onClick={(e) => handleMenuOptionToggle(e, subSections)}>
                    {isMenuOpen ? <ExpandLess /> : <ExpandMore />}
                  </IconButton>}
              </Box>
            ))}
          </Box>
          {/** END DESKTOP */}
          {/** MOBILE */}
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }} justifyContent="flex-end">
            <IconButton
              size="large"
              aria-label="open menu"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleDrawerToggle}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
          </Box>
          {/** END MOBILE */}
        </StyledToolbar>
      </AppBar>
      <DesktopMenu
        isOpen={isMenuOpen}
        handleDrawerToggle={handleDrawerToggle}
        subSections={subSections}
      />
      <MobileMenu
        isOpen={isMenuOpen}
        handleDrawerToggle={handleDrawerToggle}
        openSection={openSection}
        onClickToggleSection={handleOnClickToggle}
      />
      <div id="page-wrap" className="outlet-layout">
        <Outlet />
      </div>
      <Footer />
    </>
  )

}

export default HeaderFooterLayout;