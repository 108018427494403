import React from "react";
import orange from '@mui/material/colors/orange';
import { HashLink } from 'react-router-hash-link';
import { Link as RouterLink } from 'react-router-dom';
import { createTheme } from '@mui/material/styles';

export const LinkBehavior = React.forwardRef((props, ref) => {
  const { href, isAnchorLink, ...other } = props;
  return isAnchorLink ? <HashLink ref={ref} to={href} {...other} /> : <RouterLink ref={ref} to={href} {...other} />;
});
export default createTheme({
  components: {
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
      },
    },
  },
  status: {
    danger: orange[500],
  },
  palette: {
    primary: {
      light: '#bdddce', //icon color //bdddce
      main: '#8faa99', // bdddce
      dark: '#48554d', // eceddd
      contrastText: '#eceddd',
      white: "white"
    },
    secondary: {
      light: '#caa8bb',
      main: '#8e6f57',
      dark: '#513d2d',
      contrastText: '#f2f2f2',
    },
  },

  typography: {
    fontFamily: [
      'Merriweather Sans',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
});