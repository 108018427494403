import { createSvgIcon } from '@mui/material/utils';

export const UnisonLogo = createSvgIcon(
  <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 573.03 385.23">
    <path style={{ fill: "#94aa9b" }}
      d="M329.48,476.77v-.14a7.19,7.19,0,0,0,2.42-1.21,7,7,0,0,0,1.66-2.1,7.83,7.83,0,0,0,.75-3.73v-20H304.64v19.81a7.5,7.5,0,0,0,1,4.14,6.15,6.15,0,0,0,2.14,2.07,8.09,8.09,0,0,0,3.1.76v.13l-18.9-.07c.09,0,.41,0,1-.1l1.89-.24q1.06-.13,1.35-.21a3.2,3.2,0,0,0,1.45-.83,3.6,3.6,0,0,0,.82-1.79,17.59,17.59,0,0,0,.28-3.66V430.4a4.26,4.26,0,0,0-.76-2.93,3.31,3.31,0,0,0-1.72-1c-.33-.09-.65-.2-1-.31l-.9-.31a1.06,1.06,0,0,0-.76.14c-.23.13-.41,0-.55-.28h16.84v.14a18.4,18.4,0,0,0-2.59,1.21,8.19,8.19,0,0,0-1.79,1.41,2.62,2.62,0,0,0-.8,1.86V447.1h29.6V430.4c0-1.56-.28-2.61-.86-3.14a3.9,3.9,0,0,0-1.9-1,4.3,4.3,0,0,1-1-.24,6.06,6.06,0,0,0-.9-.24,2.61,2.61,0,0,0-.72,0c-.21,0-.34-.12-.38-.44q4,0,8.28.07c2.85,0,5.79.16,8.83.34l-1.14.21a4.89,4.89,0,0,0-.86.2,4.84,4.84,0,0,1-.76.25,3.5,3.5,0,0,0-.83.31,4,4,0,0,0-1.27,1.31,4.36,4.36,0,0,0-.52,2.34v39.06a9.74,9.74,0,0,0,.17,1.45c.12.64.27,1.3.45,2a16.56,16.56,0,0,0,.59,1.8c.2.53.38.81.51.86l1.25.65a2.57,2.57,0,0,0,1.31.31c.36.05,1.12.19,2.27.42Z"
      transform="translate(-9.57 -95.22)" />
    <path style={{ fill: "#94aa9b" }}
      d="M382.48,470.53q-.21,1.41-.42,2.76c-.14.89-.3,2-.48,3.27a1.3,1.3,0,0,0-.9-.27.68.68,0,0,1-.27.07.2.2,0,0,0-.14.06v.07c-3.73,0-6.89-.09-9.49-.13l-6.55-.11-4.32,0c-1.1,0-2,0-2.83,0s-1.48.07-2.1.14l-2,.24v-.14a5.66,5.66,0,0,0,2.8-.72,5.25,5.25,0,0,0,1.69-1.62,5.93,5.93,0,0,0,.83-2.14,12.51,12.51,0,0,0,.2-2.21V430.33a3.55,3.55,0,0,0-.89-2.58,5.84,5.84,0,0,0-2-1.38,12.8,12.8,0,0,0-2.93-.73v-.14h27.12a1.18,1.18,0,0,0,.58-.13,3.63,3.63,0,0,1,.59-.28l.55-.28c-.09.65-.23,1.51-.41,2.59s-.38,2.13-.59,3.14-.47,2.25-.79,3.73a10,10,0,0,0-.62-2.9,5,5,0,0,0-1.28-1.87,5.14,5.14,0,0,0-1.8-1,12.39,12.39,0,0,0-2.1-.44,12.93,12.93,0,0,0-2.42-.07l-2.48.13c-.83.05-1.63.06-2.41,0s-1.55-.08-2.28-.17v20.14c1.52.14,2.85.21,4,.21s2.37,0,3.66-.14a19,19,0,0,0,3.52-.55q.69-.13,1.2-.27a5.36,5.36,0,0,0,1.14-.49l.76-.41a3,3,0,0,0,.55-.35,2.73,2.73,0,0,0,.59-.62c.21-.27.49-.69.86-1.24-.09.69-.19,1.58-.31,2.66s-.23,2.19-.34,3.34-.24,2.27-.38,3.35-.23,2-.28,2.73a3.06,3.06,0,0,1-.55-1,2.78,2.78,0,0,1-.34-1.1c-.14-.42-.29-.83-.45-1.25a2.73,2.73,0,0,0-.73-1,3.64,3.64,0,0,0-2-.79,32.1,32.1,0,0,0-3.28-.38c-1.2-.1-2.46-.14-3.8-.14H364.4v19.66a8.65,8.65,0,0,0,3.93,3.42A12.67,12.67,0,0,0,374,475a10.38,10.38,0,0,0,5.45-1.59,7.72,7.72,0,0,0,3.34-5.14h.14A21,21,0,0,0,382.48,470.53Z"
      transform="translate(-9.57 -95.22)" />
    <path style={{ fill: "#94aa9b" }}
      d="M439.68,476.08l-3.83,0c-1.41,0-2.84,0-4.31,0s-2.86,0-4.28,0-2.74,0-3.93,0a3,3,0,0,1,.68-.31c.33-.12.68-.25,1.07-.42s.74-.32,1-.48a1.71,1.71,0,0,0,.59-.45l-7-18.14h-15c-.1.27-.3.81-.63,1.62s-.69,1.76-1.1,2.86-.84,2.29-1.28,3.55-.84,2.49-1.2,3.66-.67,2.23-.9,3.17a10.08,10.08,0,0,0-.35,2.11,2,2,0,0,0,1.14,2,9.92,9.92,0,0,0,2.8.72v.14l-13.11-.28a4.22,4.22,0,0,1-1.11.14,3.71,3.71,0,0,1-1.24-.28l2.35-.76a10.72,10.72,0,0,0,3.27-1.65,6.37,6.37,0,0,0,2.32-3.18q4.06-11.58,8.38-23t8.73-23.12l.45.62c.25.37.58.9,1,1.59a20.83,20.83,0,0,1,1,1.93c.42,1.06.81,2.11,1.17,3.14s.7,1.95,1,2.73q3.32,8.49,7,17.9t8.07,19.77a6.51,6.51,0,0,0,1.07,1.52,7.58,7.58,0,0,0,1.55,1.38,7,7,0,0,0,2.21.9c.83.18,1.64.32,2.42.41Zm-27.39-41.4a1.57,1.57,0,0,0-.7.35c-.36,1.24-.79,2.63-1.27,4.17s-1,3.14-1.55,4.8-1.11,3.32-1.66,5-1.1,3.28-1.66,4.79H418.7Z"
      transform="translate(-9.57 -95.22)" />
    <path style={{ fill: "#94aa9b" }}
      d="M470.76,469.66c-.2,1.06-.41,2.11-.62,3.14s-.47,2.31-.79,3.83H439.54v-.14a4.85,4.85,0,0,0,2.69-.75,4.37,4.37,0,0,0,1.45-1.7,6.2,6.2,0,0,0,.59-2.13c.07-.76.1-1.46.1-2.11V430.4a4.26,4.26,0,0,0-.76-2.93,3.35,3.35,0,0,0-1.72-1l-1-.35-1.45-.48a6,6,0,0,0-.73-.21l17.53.07c-.09.05-.45.19-1.07.42a13.29,13.29,0,0,0-2,1,9.12,9.12,0,0,0-1.93,1.49,2.69,2.69,0,0,0-.87,2v36.16a8.15,8.15,0,0,0,1.18,4.48,7.59,7.59,0,0,0,3.07,2.83,10.38,10.38,0,0,0,4.21,1.11,11.61,11.61,0,0,0,4.62-.69,14.07,14.07,0,0,0,4.35-2.56,13.19,13.19,0,0,0,3.34-4.48A20.61,20.61,0,0,1,470.76,469.66Z"
      transform="translate(-9.57 -95.22)" />
    <path style={{ fill: "#94aa9b" }}
      d="M504.64,427.54q0,1.14,0,2.31c0,.74,0,1.5-.11,2.28a22.57,22.57,0,0,1-.38,2.55,8.87,8.87,0,0,0-1-2.93,6.3,6.3,0,0,0-1.62-1.83,6,6,0,0,0-2.11-1,11.32,11.32,0,0,0-2.41-.38c-.69-.05-1.52-.06-2.49,0l-2.89.07-2.73.07a9.91,9.91,0,0,1-2-.1v40.92c0,.23.12.64.24,1.24s.24,1.22.38,1.86a16,16,0,0,0,.45,1.76,2.9,2.9,0,0,0,.44,1,4.59,4.59,0,0,0,2,1,11.73,11.73,0,0,0,2,.38c.53.05.84.07.93.07v.14c-2.57,0-4.78.07-6.62.07s-3.72,0-5.76,0-3.83-.08-5.35-.17l1.69-.31c.39-.07.75-.15,1.07-.24a2.29,2.29,0,0,0,.86-.45,5.14,5.14,0,0,0,.66-.66,3.83,3.83,0,0,0,.86-1.72,13.86,13.86,0,0,0,.31-3.38V428.54H469.21a7.06,7.06,0,0,0-3.52,1.14,8.91,8.91,0,0,0-3.31,4.17s.06-.3.17-.76.27-1,.45-1.76.32-1.37.41-2a20.45,20.45,0,0,0,.35-2.62,10.08,10.08,0,0,1,.41-2.35,7.55,7.55,0,0,0,1.38.55,17.62,17.62,0,0,0,1.87.48c.64.14,1.24.26,1.79.35a8.63,8.63,0,0,0,1.17.14h30.29a6,6,0,0,0,.73-.07c.3-.05.63-.12,1-.21l1.21-.31c.43-.11.86-.22,1.27-.31A25.27,25.27,0,0,0,504.64,427.54Z"
      transform="translate(-9.57 -95.22)" />
    <path style={{ fill: "#94aa9b" }}
      d="M544.63,476.77v-.14a7.23,7.23,0,0,0,2.41-1.21,7,7,0,0,0,1.66-2.1,7.83,7.83,0,0,0,.76-3.73v-20H519.79v19.81a7.5,7.5,0,0,0,1,4.14,6.23,6.23,0,0,0,2.14,2.07,8.09,8.09,0,0,0,3.11.76v.13l-18.91-.07c.09,0,.41,0,1-.1l1.9-.24a13.19,13.19,0,0,0,1.34-.21,3.2,3.2,0,0,0,1.45-.83,3.6,3.6,0,0,0,.83-1.79,18.47,18.47,0,0,0,.27-3.66V430.4a4.26,4.26,0,0,0-.76-2.93,3.31,3.31,0,0,0-1.72-1c-.32-.09-.65-.2-1-.31l-.89-.31a1.05,1.05,0,0,0-.76.14c-.23.13-.42,0-.55-.28H525v.14a18.4,18.4,0,0,0-2.59,1.21,8.43,8.43,0,0,0-1.79,1.41,2.61,2.61,0,0,0-.79,1.86V447.1h29.6V430.4c0-1.56-.29-2.61-.87-3.14a3.87,3.87,0,0,0-1.89-1,4.27,4.27,0,0,1-1-.24,6.06,6.06,0,0,0-.9-.24,2.61,2.61,0,0,0-.72,0c-.21,0-.33-.12-.38-.44q4,0,8.28.07c2.85,0,5.8.16,8.83.34l-1.14.21a4.89,4.89,0,0,0-.86.2,4.84,4.84,0,0,1-.76.25,3.44,3.44,0,0,0-.82.31,3.92,3.92,0,0,0-1.28,1.31,4.36,4.36,0,0,0-.52,2.34v39.06a9.74,9.74,0,0,0,.17,1.45c.12.64.27,1.3.45,2s.38,1.27.59,1.8.38.81.52.86c.46.23.87.45,1.24.65a2.59,2.59,0,0,0,1.31.31q.55.07,2.28.42Z"
      transform="translate(-9.57 -95.22)" />
    <rect style={{ fill: "#ebebde" }} y="169.79" width="215.45" height="215.45" />
    <path style={{ fill: "#94aa9b" }}
      d="M16.23,273.39l-.39,35c.54,0,6.61-.18,10.13-5.41a12.78,12.78,0,0,0,1.53-10.43c3.12,3.63,7.86,4,10,2.22,2.36-2,2.6-7.51-1.53-11.12a14,14,0,0,0,10.28-3.75,13.22,13.22,0,0,0,3.56-6.35Z"
      transform="translate(-9.57 -95.22)" />
    <path style={{ fill: "#94aa9b" }}
      d="M15.35,471.46l35,.38a12.92,12.92,0,0,0-5.41-10.12,12.78,12.78,0,0,0-10.43-1.53c3.64-3.12,4-7.86,2.23-10-2-2.36-7.51-2.6-11.12,1.53a14,14,0,0,0-3.76-10.29,13.35,13.35,0,0,0-6.35-3.56Z"
      transform="translate(-9.57 -95.22)" />
    <path style={{ fill: "#94aa9b" }}
      d="M217.13,472.34l.39-35c-.53,0-6.61.18-10.12,5.42a12.71,12.71,0,0,0-1.53,10.42c-3.13-3.63-7.86-4-10-2.22-2.36,2-2.6,7.51,1.53,11.12a14,14,0,0,0-10.29,3.75,13.39,13.39,0,0,0-3.56,6.35Z"
      transform="translate(-9.57 -95.22)" />
    <path style={{ fill: "#c4dccf" }}
      d="M116.1,275.26l26.81-.23a3.78,3.78,0,0,1-.54,2.68,4.13,4.13,0,0,1-2.83,1.38c-3.18.44-6.46,3.89-10.19,7.81a42,42,0,0,0-8.81,14.17,4.63,4.63,0,0,1-4.29,2.76Z"
      transform="translate(-9.57 -95.22)" />
    <path style={{ fill: "#c4dccf" }}
      d="M116.5,275.26,89.69,275a3.78,3.78,0,0,0,.54,2.68,4.13,4.13,0,0,0,2.83,1.38c3.18.44,6.46,3.89,10.19,7.81a42,42,0,0,1,8.81,14.17,4.55,4.55,0,0,0,1.68,2,4.69,4.69,0,0,0,2.6.77Q116.43,289.54,116.5,275.26Z"
      transform="translate(-9.57 -95.22)" />
    <path style={{ fill: "#c4dccf" }}
      d="M116.7,471.61l-26.81.23a3.78,3.78,0,0,1,.54-2.68,4.18,4.18,0,0,1,2.83-1.38c3.18-.43,6.46-3.89,10.19-7.81a41.72,41.72,0,0,0,8.8-14.17,4.63,4.63,0,0,1,4.29-2.75Q116.62,457.33,116.7,471.61Z"
      transform="translate(-9.57 -95.22)" />
    <path style={{ fill: "#c4dccf" }}
      d="M116.3,471.61l26.8.23a3.72,3.72,0,0,0-.53-2.68,4.18,4.18,0,0,0-2.84-1.38c-3.17-.43-6.45-3.89-10.18-7.81a41.94,41.94,0,0,1-8.81-14.17,4.6,4.6,0,0,0-4.29-2.75Z"
      transform="translate(-9.57 -95.22)" />
    <path style={{ fill: "#c4dccf" }}
      d="M17.92,373q-.11-13.41-.23-26.81a3.76,3.76,0,0,1,2.68.54,4.13,4.13,0,0,1,1.38,2.83c.44,3.18,3.89,6.46,7.81,10.19a42.13,42.13,0,0,0,14.17,8.81,4.63,4.63,0,0,1,2.76,4.29Z"
      transform="translate(-9.57 -95.22)" />
    <path style={{ fill: "#c4dccf" }}
      d="M17.92,372.63q-.11,13.41-.23,26.81a3.76,3.76,0,0,0,2.68-.54,4.13,4.13,0,0,0,1.38-2.83c.44-3.18,3.89-6.46,7.81-10.19a42.13,42.13,0,0,1,14.17-8.81,4.63,4.63,0,0,0,2-1.68,4.7,4.7,0,0,0,.77-2.61Z"
      transform="translate(-9.57 -95.22)" />
    <path style={{ fill: "#c4dccf" }}
      d="M214.28,372.43q.1,13.41.23,26.81a3.76,3.76,0,0,1-2.68-.54,4.13,4.13,0,0,1-1.38-2.83c-.44-3.18-3.89-6.46-7.81-10.19a42.13,42.13,0,0,0-14.17-8.81,4.55,4.55,0,0,1-2-1.68,4.69,4.69,0,0,1-.77-2.6Z"
      transform="translate(-9.57 -95.22)" />
    <path style={{ fill: "#c4dccf" }}
      d="M214.28,372.83q.1-13.4.23-26.8a3.7,3.7,0,0,0-2.68.53,4.13,4.13,0,0,0-1.38,2.84c-.44,3.17-3.89,6.45-7.81,10.18a42.13,42.13,0,0,1-14.17,8.81,4.63,4.63,0,0,0-2.76,4.29Z"
      transform="translate(-9.57 -95.22)" />
    <path style={{ fill: "#b5c6b9" }}
      d="M84.4,289.73c-.64.49-.9,1.18-1.89,1.46a2.21,2.21,0,0,1-2.14-.35c-.68-.73-.29-2.26.29-3.14,1.9-2.86,7.77-1.75,8.2-1.66a13.32,13.32,0,0,1,7.55,4.63,14.12,14.12,0,0,1,2.49,11.16,4.27,4.27,0,0,0,.94,3.69,4.12,4.12,0,0,0,4.2,1.11c.13.09,1.27,1,1.12,2s-1.64,1.58-2.4,1.72c-2.77.49-6-2.06-7-4.72a9.67,9.67,0,0,1-.51-2.52,14.65,14.65,0,0,1,.23-2.89A12.07,12.07,0,0,0,95,296a9.48,9.48,0,0,0-4.39-5.61C90.45,290.25,86.47,288.16,84.4,289.73Z"
      transform="translate(-9.57 -95.22)" />
    <path style={{ fill: "#b5c6b9" }}
      d="M149.21,289.73c.65.49.91,1.18,1.89,1.46a2.23,2.23,0,0,0,2.15-.35c.67-.73.28-2.26-.3-3.14-1.9-2.86-7.77-1.75-8.2-1.66a13.36,13.36,0,0,0-7.55,4.63,14.06,14.06,0,0,0-2.48,11.16,4.29,4.29,0,0,1-.95,3.69,4.12,4.12,0,0,1-4.2,1.11c-.13.09-1.27,1-1.12,2s1.64,1.58,2.4,1.72c2.77.49,6-2.06,7-4.72a9.88,9.88,0,0,0,.52-2.52,15.39,15.39,0,0,0-.24-2.89,12.07,12.07,0,0,1,.5-4.23,9.52,9.52,0,0,1,4.39-5.61C143.16,290.25,147.14,288.16,149.21,289.73Z"
      transform="translate(-9.57 -95.22)" />
    <path style={{ fill: "#b5c6b9" }}
      d="M148.51,458.52c.64-.49.9-1.18,1.89-1.46a2.24,2.24,0,0,1,2.14.34c.68.74.29,2.27-.3,3.15-1.9,2.85-7.76,1.74-8.19,1.65a13.32,13.32,0,0,1-7.55-4.63A14.06,14.06,0,0,1,134,446.42a4.31,4.31,0,0,0-.94-3.69,4.12,4.12,0,0,0-4.21-1.12c-.12-.09-1.26-1-1.11-2s1.64-1.58,2.4-1.71c2.77-.49,6,2.05,6.95,4.71a10.19,10.19,0,0,1,.51,2.53,15.54,15.54,0,0,1-.24,2.89,12.3,12.3,0,0,0,.5,4.22,9.54,9.54,0,0,0,4.4,5.62C142.45,458,146.44,460.08,148.51,458.52Z"
      transform="translate(-9.57 -95.22)" />
    <path style={{ fill: "#b5c6b9" }}
      d="M83.69,458.52c-.64-.49-.9-1.18-1.89-1.46a2.24,2.24,0,0,0-2.14.34c-.68.74-.29,2.27.3,3.15,1.9,2.85,7.76,1.74,8.19,1.65a13.32,13.32,0,0,0,7.55-4.63,14.06,14.06,0,0,0,2.49-11.15,4.31,4.31,0,0,1,.94-3.69,4.13,4.13,0,0,1,4.21-1.12c.12-.09,1.26-1,1.11-2s-1.64-1.58-2.4-1.71c-2.77-.49-6,2.05-7,4.71a10.19,10.19,0,0,0-.51,2.53,15.54,15.54,0,0,0,.24,2.89,12,12,0,0,1-.5,4.22,9.49,9.49,0,0,1-4.4,5.62C89.75,458,85.76,460.08,83.69,458.52Z"
      transform="translate(-9.57 -95.22)" />
    <path style={{ fill: "#b5c6b9" }}
      d="M199.36,343.84c-.49-.64-1.18-.9-1.46-1.89a2.23,2.23,0,0,1,.34-2.14c.74-.68,2.27-.29,3.15.3,2.85,1.9,1.74,7.76,1.66,8.19a13.26,13.26,0,0,1-4.64,7.55,14.1,14.1,0,0,1-11.15,2.49,4.27,4.27,0,0,0-3.69.94,4.1,4.1,0,0,0-1.11,4.2c-.1.13-1,1.27-2,1.12s-1.58-1.64-1.71-2.4c-.49-2.77,2.06-6,4.72-6.95a9.59,9.59,0,0,1,2.52-.51,14,14,0,0,1,2.89.24,12.3,12.3,0,0,0,4.22-.5,9.54,9.54,0,0,0,5.62-4.4C198.84,349.89,200.92,345.91,199.36,343.84Z"
      transform="translate(-9.57 -95.22)" />
    <path style={{ fill: "#b5c6b9" }}
      d="M199.36,408.66c-.49.64-1.18.9-1.46,1.88a2.25,2.25,0,0,0,.34,2.15c.74.67,2.27.28,3.15-.3,2.85-1.9,1.74-7.77,1.66-8.2a13.3,13.3,0,0,0-4.64-7.55,14,14,0,0,0-11.15-2.48,4.29,4.29,0,0,1-3.69-1,4.1,4.1,0,0,1-1.11-4.2c-.1-.13-1-1.26-2-1.12s-1.58,1.64-1.71,2.41c-.49,2.77,2.06,5.95,4.72,6.94a9.8,9.8,0,0,0,2.52.52,15.54,15.54,0,0,0,2.89-.24,12,12,0,0,1,4.22.5,9.49,9.49,0,0,1,5.62,4.4C198.84,402.6,200.92,406.59,199.36,408.66Z"
      transform="translate(-9.57 -95.22)" />
    <path style={{ fill: "#b5c6b9" }}
      d="M34,408.66c.49.64,1.18.9,1.46,1.88a2.25,2.25,0,0,1-.34,2.15c-.74.67-2.27.28-3.15-.3-2.85-1.9-1.74-7.77-1.66-8.2A13.3,13.3,0,0,1,35,396.64a14,14,0,0,1,11.15-2.48,4.29,4.29,0,0,0,3.69-1,4.1,4.1,0,0,0,1.11-4.2c.09-.13,1-1.26,2-1.12s1.58,1.64,1.71,2.41c.49,2.77-2.06,5.95-4.72,6.94a9.88,9.88,0,0,1-2.52.52,15.54,15.54,0,0,1-2.89-.24,12.07,12.07,0,0,0-4.23.5,9.51,9.51,0,0,0-5.61,4.4C34.56,402.6,32.47,406.59,34,408.66Z"
      transform="translate(-9.57 -95.22)" />
    <path style={{ fill: "#b5c6b9" }}
      d="M34,343.84c.49-.64,1.18-.9,1.46-1.89a2.23,2.23,0,0,0-.34-2.14c-.74-.68-2.27-.29-3.15.3-2.85,1.9-1.74,7.76-1.66,8.19A13.26,13.26,0,0,0,35,355.85a14.1,14.1,0,0,0,11.15,2.49,4.27,4.27,0,0,1,3.69.94,4.1,4.1,0,0,1,1.11,4.2c.09.13,1,1.27,2,1.12s1.58-1.64,1.71-2.4c.49-2.77-2.06-6-4.72-6.95a9.67,9.67,0,0,0-2.52-.51,14,14,0,0,0-2.89.24,12.36,12.36,0,0,1-4.23-.5,9.55,9.55,0,0,1-5.61-4.4C34.56,349.89,32.47,345.91,34,343.84Z"
      transform="translate(-9.57 -95.22)" />
    <ellipse style={{ fill: "#c3dcce" }} cx="227.7" cy="38.44" rx="35.89" ry="38.44" />
    <ellipse style={{ fill: "#c3dcce" }} cx="227.7" cy="92.19" rx="61.81" ry="57.71" />
    <ellipse style={{ fill: "#c3dcce" }} cx="227.7" cy="179.26" rx="75.7" ry="70.68" />
    <rect style={{ fill: "#c3dcce" }} x="215.45" y="247.01" width="24.22" height="138.22" />
    <rect style={{ fill: "#ebebde" }} x="217.08" y="33.68" width="20.96" height="196.61" rx="10.48" />
    <rect style={{ fill: "#ebebde" }} x="249.1" y="169.64" width="14.24" height="73.29" rx="7.12"
      transform="translate(114.79 -190.29) rotate(27.53)" />
    <rect style={{ fill: "#ebebde" }} x="210.75" y="256.16" width="15.67" height="60.51" rx="7.84"
      transform="translate(-148.32 237.29) rotate(-60)" />
    <path style={{ fill: "#c4dccf" }}
      d="M62.94,352.36c9.7-3.86,20.52-1.38,26.62,5.89,8.1,9.65,6,25.49-4,32.5-6.6,4.63-15.82,4.84-23.79.71a13.83,13.83,0,0,0,8.72,12.72c-7.74,5.77-9.92,14.07-7.06,18.41,2.49,3.78,8.33,3.85,9,3.85,7.54,0,11.8-6.77,12.1-7.26a13.41,13.41,0,0,0,6.7,6.27,13,13,0,0,0,6.38.88,25.79,25.79,0,0,1-2.31-8.69,23.71,23.71,0,0,1,1.21-10.22c3.17-7.88,13.3-13.06,22.76-12.2,1.7.15,11.86,1.07,16.93,10A20.9,20.9,0,0,1,135,427a9.92,9.92,0,0,0,6.19.88c5.63-1.11,7.66-7.1,7.74-7.35,4.78,7,12.19,9.66,17.22,7.35,3.92-1.79,5.22-6.07,5.42-6.77,1.64-5.73-1.45-12.62-7.94-17a9.67,9.67,0,0,0,7.55-12.38,19.33,19.33,0,0,1-8.32,2.71,19.74,19.74,0,0,1-8.32-1.16,23.47,23.47,0,0,1-10.45-7.16c-5.48-6.83-6.82-17.95-1.35-26.31a22.59,22.59,0,0,1,14.51-9.48A22,22,0,0,1,171,352.61a13.94,13.94,0,0,0-1-5,14.56,14.56,0,0,0-6.39-7.16,20.56,20.56,0,0,0,6.77-7c1.3-2.22,2.85-4.87,2.33-8.32a9.45,9.45,0,0,0-2.91-5.61c-4.49-4-11.65-1.21-12.76-.78a17.27,17.27,0,0,0-7.84,6.29,13.07,13.07,0,0,0-5.73-5.82,12.75,12.75,0,0,0-6.5-1.36,24.19,24.19,0,0,1,2.14,9.31,22.57,22.57,0,0,1-1.56,9.13c-3.26,8-10.82,11.28-12.12,11.83-7.46,3.13-18.19,2.86-24.55-3.78-5-5.19-5.3-12.19-5.44-15.24a28.88,28.88,0,0,1,1.94-11.74,16.48,16.48,0,0,0-4.75,1.36,16.7,16.7,0,0,0-6.89,6,14.08,14.08,0,0,0-5-5.05,15.64,15.64,0,0,0-5.92-1.84c-3.18-.5-5.34-.84-7.66.29a9.57,9.57,0,0,0-4.66,5.63c-.93,3.2.28,5.93,1.63,9a25.2,25.2,0,0,0,5.55,7.9,12,12,0,0,0-5.72,5.73A11.65,11.65,0,0,0,62.94,352.36Z"
      transform="translate(-9.57 -95.22)" />
    <circle style={{ fill: "#c5dccf" }} cx="36.92" cy="208.6" r="7.4" />
    <circle style={{ fill: "#c5dccf" }} cx="36.92" cy="342.15" r="7.4" />
    <circle style={{ fill: "#c5dccf" }} cx="174.51" cy="342.15" r="7.4" />
    <path style={{ fill: "#94aa9b" }}
      d="M342.81,355.39l-.79.23a5,5,0,0,0-2.41,1.36,3.79,3.79,0,0,0-.89,2.67v25.54a19.16,19.16,0,0,1-6,14.36,20.28,20.28,0,0,1-6.18,4,22.66,22.66,0,0,1-7.09,1.65,21.48,21.48,0,0,1-7.1-.73,15.93,15.93,0,0,1-10.52-9,19.93,19.93,0,0,1-1.65-8.45V361a6.17,6.17,0,0,0-.66-3.11,4.68,4.68,0,0,0-1.55-1.65,4.4,4.4,0,0,0-1.75-.62,11.2,11.2,0,0,0-1.26-.1v-.13l2.15.06c1.08.05,2.36.09,3.86.13s3.13.06,4.92,0,3.53-.1,5.24-.23a8.73,8.73,0,0,0-4.09,1.18,3.62,3.62,0,0,0-1.12,3v27a14.52,14.52,0,0,0,1.52,8.85,13.21,13.21,0,0,0,5.41,5.31,16.07,16.07,0,0,0,14.72-.13,13.65,13.65,0,0,0,5.41-5.38,14.73,14.73,0,0,0,1.52-8.91V361a6.14,6.14,0,0,0-.76-3.34A5.16,5.16,0,0,0,332,356a4.77,4.77,0,0,0-1.85-.66c-.57-.07-.92-.1-1.05-.1v-.13l1.88,0,3.86-.06q2.24,0,4.68,0c1.63,0,3.08.07,4.36.16Z"
      transform="translate(-9.57 -95.22)" />
    <path style={{ fill: "#94aa9b" }}
      d="M395.85,355.29a3.79,3.79,0,0,0-.7.2,10.69,10.69,0,0,0-2.24,1,2.25,2.25,0,0,0-1.19,2.07v35.58c0,.17,0,.66,0,1.45s-.06,1.72-.1,2.77-.12,2.18-.23,3.37-.23,2.24-.37,3.17q-3.75-3.24-8.58-8.09t-10-10.36q-5.15-5.5-10.3-11.22t-9.57-10.79c0,.26-.09.49-.13.69a5.77,5.77,0,0,0-.1.79c0,.33-.05.77-.1,1.32s-.06,1.27-.06,2.15,0,2.09,0,3.5.07,3.14.13,5.21.15,4.51.27,7.33.23,6.09.36,9.83a9.53,9.53,0,0,1,0,1.52,10.31,10.31,0,0,0,.06,2,9.36,9.36,0,0,0,.47,1.91,3.81,3.81,0,0,0,1.18,1.65,2.21,2.21,0,0,0,1,.56q.6.16,1.32.3a2.67,2.67,0,0,0,.66.17,2.27,2.27,0,0,1,.66.16c.22.09.43.17.63.23a1.73,1.73,0,0,1,.63.43c-1.32,0-2.91-.08-4.75-.1l-5.28-.06c-1.68,0-3.11,0-4.29,0h-1.72a5.68,5.68,0,0,1,1-.33,14,14,0,0,0,1.88-.72,9,9,0,0,0,1.81-1.13,1.87,1.87,0,0,0,.83-1.45V355.43a1.61,1.61,0,0,1,.53-1.19l.26-.27q2.25,2.31,5.44,5.75T362,367l7.22,7.85,7,7.62q3.3,3.6,6,6.54c1.78,2,3.17,3.48,4.16,4.58l1.55,1.72c-.09-2.33-.15-4.84-.2-7.52s-.06-6.21-.06-10.56,0-9.82.13-16.24a5.82,5.82,0,0,0-.73-3.56,3.63,3.63,0,0,0-1.84-1.39l-.76-.23-1.22-.33c-.44-.11-.83-.22-1.16-.33a4.58,4.58,0,0,0-.56-.17l7.56-.1q3.85,0,7.62.17A3.8,3.8,0,0,1,395.85,355.29Z"
      transform="translate(-9.57 -95.22)" />
    <path style={{ fill: "#94aa9b" }}
      d="M419.18,403.74l-3.17-.13c-.88-.05-1.81-.08-2.77-.1s-1.81,0-2.51,0-1.67,0-2.77,0l-3.21.06-3.79.1.07-.13a24.87,24.87,0,0,0,3.07-1,12.56,12.56,0,0,0,2.11-1.16,1.83,1.83,0,0,0,1-1.45l.07-40.13a2.52,2.52,0,0,0-.89-1.88,10.59,10.59,0,0,0-1.92-1.42,16,16,0,0,0-2.8-1.19h16.43l-1.91.33c-.75.27-1.25.47-1.52.6a3.3,3.3,0,0,0-1.22,1.19,4.36,4.36,0,0,0-.49,2.31V397c0,.17,0,.45.06.82a7,7,0,0,0,.27,1.22,8,8,0,0,0,.52,1.32,4.58,4.58,0,0,0,.86,1.19,5.33,5.33,0,0,0,1.78,1.22,18.78,18.78,0,0,0,1.89.73A5.79,5.79,0,0,0,419.18,403.74Z"
      transform="translate(-9.57 -95.22)" />
    <path style={{ fill: "#94aa9b" }}
      d="M449.74,392.32a10.79,10.79,0,0,1-1.72,5,13.84,13.84,0,0,1-3.8,3.83,22.52,22.52,0,0,1-5,2.61,21.19,21.19,0,0,1-5.38,1.25,14.37,14.37,0,0,1-4.85-.26,7.09,7.09,0,0,1-3.47-1.88,4.62,4.62,0,0,1-1.18-3.67,12.54,12.54,0,0,1,.23-1.58c.11-.53.23-1.06.36-1.58s.25-1,.36-1.52.21-1,.3-1.45h.13a7.69,7.69,0,0,0,1.45,5.84,9.25,9.25,0,0,0,4.72,3.2,11.63,11.63,0,0,0,6,.13,9.63,9.63,0,0,0,5.31-3.3,7.51,7.51,0,0,0,1.72-3.33,7,7,0,0,0-.17-3.37,12.51,12.51,0,0,0-1.62-3.37,26.33,26.33,0,0,0-2.7-3.39c-1-1.15-2.12-2.29-3.33-3.44s-2.41-2.28-3.6-3.43-2.32-2.3-3.4-3.46a30.14,30.14,0,0,1-2.81-3.53,11.5,11.5,0,0,1-2.27-5.71,8.82,8.82,0,0,1,1.05-4.89,10.9,10.9,0,0,1,3.63-3.79,20.27,20.27,0,0,1,5.45-2.48,23.74,23.74,0,0,1,6.47-.92,24.67,24.67,0,0,1,6.8.89c-.09.84-.23,1.68-.4,2.54a16,16,0,0,0-.33,2.48,18.46,18.46,0,0,0,.13,2.31,2.19,2.19,0,0,1-.59,1.84,7.55,7.55,0,0,0-1.06-4.06,7.69,7.69,0,0,0-2.74-2.64,9.64,9.64,0,0,0-3.69-1.18,8.91,8.91,0,0,0-3.86.36,8.08,8.08,0,0,0-3.3,2,7.58,7.58,0,0,0-2,3.76,8.09,8.09,0,0,0,0,3.46,10,10,0,0,0,1.29,3.11,17.86,17.86,0,0,0,2.28,2.9c.9.95,1.88,1.88,2.93,2.8s2.21,1.84,3.34,2.74,2.18,1.86,3.2,2.87a29.17,29.17,0,0,1,2.87,3,15.81,15.81,0,0,1,2.08,3.3A11.73,11.73,0,0,1,449.74,392.32Z"
      transform="translate(-9.57 -95.22)" />
    <path style={{ fill: "#94aa9b" }}
      d="M505.21,384a21.6,21.6,0,0,1-3.1,9.34,22.26,22.26,0,0,1-7.53,7.22,35.46,35.46,0,0,1-12.5,4.33,18,18,0,0,1-5.81,0,24.82,24.82,0,0,1-6.11-1.85,31.78,31.78,0,0,1-5.87-3.39,29.49,29.49,0,0,1-5.08-4.72,27.63,27.63,0,0,1-3.83-5.84,20.32,20.32,0,0,1-1.95-6.67,29.37,29.37,0,0,1,.36-10,21.21,21.21,0,0,1,3.83-8.31,24.11,24.11,0,0,1,7.46-6.34,38.88,38.88,0,0,1,11.32-4,20.44,20.44,0,0,1,9.24.13,24.45,24.45,0,0,1,8.38,3.83,26,26,0,0,1,6.57,6.76,26.64,26.64,0,0,1,3.86,8.94A33,33,0,0,1,505.21,384Zm-14.62,15.74a18.31,18.31,0,0,0,5.45-6.07,24.7,24.7,0,0,0,2.73-8,30,30,0,0,0,.17-8.78,31.72,31.72,0,0,0-2.31-8.48,23,23,0,0,0-4.65-7,18.66,18.66,0,0,0-6.87-4.45,17.86,17.86,0,0,0-9-.76,18.74,18.74,0,0,0-8.42,3.23,19.3,19.3,0,0,0-5.51,6.07,22.64,22.64,0,0,0-2.71,7.86,30.29,30.29,0,0,0-.09,8.61,28.9,28.9,0,0,0,2.37,8.32,23.68,23.68,0,0,0,4.72,6.93,19.54,19.54,0,0,0,6.9,4.49,18.94,18.94,0,0,0,8.94,1A17.17,17.17,0,0,0,490.59,399.78Z"
      transform="translate(-9.57 -95.22)" />
    <path style={{ fill: "#94aa9b" }}
      d="M561.38,355.29a3.79,3.79,0,0,0-.7.2,10.69,10.69,0,0,0-2.24,1,2.25,2.25,0,0,0-1.19,2.07v35.58c0,.17,0,.66,0,1.45s-.06,1.72-.1,2.77-.12,2.18-.23,3.37-.23,2.24-.37,3.17q-3.75-3.24-8.58-8.09t-10-10.36q-5.15-5.5-10.3-11.22t-9.57-10.79c0,.26-.09.49-.13.69a5.77,5.77,0,0,0-.1.79c0,.33,0,.77-.1,1.32s-.06,1.27-.06,2.15,0,2.09,0,3.5.07,3.14.13,5.21.16,4.51.27,7.33.23,6.09.36,9.83a9.53,9.53,0,0,1,0,1.52,10.31,10.31,0,0,0,.06,2,9.36,9.36,0,0,0,.47,1.91,3.81,3.81,0,0,0,1.18,1.65,2.21,2.21,0,0,0,1,.56q.6.16,1.32.3a2.67,2.67,0,0,0,.66.17,2.27,2.27,0,0,1,.66.16c.22.09.43.17.63.23a1.73,1.73,0,0,1,.63.43c-1.32,0-2.91-.08-4.75-.1l-5.28-.06c-1.68,0-3.11,0-4.29,0h-1.72a5.68,5.68,0,0,1,1-.33,14,14,0,0,0,1.88-.72,9,9,0,0,0,1.81-1.13,1.87,1.87,0,0,0,.83-1.45V355.43a1.61,1.61,0,0,1,.53-1.19l.26-.27c1.5,1.54,3.31,3.46,5.45,5.75s4.37,4.7,6.73,7.26l7.22,7.85,7,7.62q3.3,3.6,6,6.54c1.79,2,3.17,3.48,4.16,4.58l1.55,1.72c-.09-2.33-.15-4.84-.2-7.52s-.06-6.21-.06-10.56,0-9.82.13-16.24a5.82,5.82,0,0,0-.73-3.56,3.63,3.63,0,0,0-1.84-1.39l-.76-.23-1.22-.33c-.44-.11-.83-.22-1.16-.33A4.58,4.58,0,0,0,547,355l7.56-.1q3.85,0,7.62.17A3.8,3.8,0,0,1,561.38,355.29Z"
      transform="translate(-9.57 -95.22)" />
    <path style={{ fill: "#94aa9b" }}
      d="M557.19,426.68c-.51-.58-.55-.83-.48-1,.2-.34,1.23.2,2.58,0a4.83,4.83,0,0,0,1.86-.79,9,9,0,0,0,3-3,8.1,8.1,0,0,1,2.4-4.64c1.4-1.24,3.39-1.66,7.3-2.43,3.6-.71,5-.62,6.52-1.95,1.34-1.13,1.71-2.36,2-2.24.54.21.11,3.72-1.37,6.82a13.57,13.57,0,0,1-4.86,5.94c-5.1,3.23-11,1.08-11.78.78a11.15,11.15,0,0,1-2,1.29,7.32,7.32,0,0,1-2.29.81,3.87,3.87,0,0,1-1.48,0,1.72,1.72,0,0,0-.29-.05A1.64,1.64,0,0,0,557.19,426.68Z"
      transform="translate(-9.57 -95.22)" />
  </svg>,
  "UnisonLogo"
);