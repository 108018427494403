import Drawer from '@mui/material/Drawer';
import { HEADER_HEIGHT } from "../../constants";
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

function DesktopMenu(props) {
  const { isOpen, handleDrawerToggle, subSections } = props;
  const container = window.document.body;
  return (
    <nav>
      <Drawer
        anchor="top"
        container={container}
        variant="temporary"
        open={isOpen}
        onClose={handleDrawerToggle}
        PaperProps={{ sx: { marginTop: `${HEADER_HEIGHT}px` } }}
        sx={{
          display: { xs: 'none', md: 'flex' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box' },
        }}
      >
        <Box onClick={handleDrawerToggle} sx={{ flexGrow: 1, display: 'flex', paddingBottom: 4, paddingTop: 2 }} justifyContent="space-around">
          {subSections.map(({ link, text, extraListInfo }) => (
            <Box key={text} >
              <Link href={link} sx={{ textDecoration: "none" }} ><Typography variant="h6" textAlign="left" sx={{
                marginBottom: 2, textDecoration: "none", "&:hover": {
                  textDecoration: "underline"
                }
              }} >{text}</Typography></Link>
              {extraListInfo.map(textInfo => (
                <Typography key={textInfo} variant="body2" textAlign="left" >{textInfo}</Typography>
              ))}

            </Box>
          ))
          }
        </Box>
      </Drawer>
    </nav>
  )
}

export default DesktopMenu;