import React, { Suspense } from "react";
import HeaderFooterLayout from "./components/HeaderFooterLayout/HeaderFooterLayout";
import { Navigate } from "react-router-dom";

// Carga perezosa de los componentes
const Home = React.lazy(() => import("./pages/Home.page"));
const FaqPage = React.lazy(() => import("./pages/Faq.page"));
const ContactUs = React.lazy(() => import("./pages/ContactUs.page"));

const routes = [
  {
    path: "/",
    element: <HeaderFooterLayout />,
    children: [
      {
        path: "/",
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <Home />
          </Suspense>
        ),
      },
      {
        path: "/faq",
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <FaqPage />
          </Suspense>
        ),
      },
      {
        path: "/contact-us",
        element: (
          <Suspense fallback={<div>Loading...</div>}>
            <ContactUs />
          </Suspense>
        ),
      },
    ]
  },
  {
    path: "*",
    element: <Navigate to="/" replace />
  }
]

export default routes;