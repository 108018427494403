import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';
import ContactSupportOutlinedIcon from '@mui/icons-material/ContactSupportOutlined';

export const menuItems = [
  {
    id: '1',
    link: "/#programs",
    text: "Programs",
    isAnchor: true,
    icon: AssessmentOutlinedIcon
  },
  {
    id: '3',
    link: "/#location",
    text: "Location",
    isAnchor: true,
    icon: LocationOnOutlinedIcon
  },
  {
    id: '4',
    link: "/contact-us",
    text: "Contact us",
    isAnchor: false,
    icon: ContactSupportOutlinedIcon
  },
  {
    id: '5',
    link: "/faq",
    text: "Q&A",
    isAnchor: false,
    icon: QuestionAnswerOutlinedIcon
  },
];

